<template>
  <div class="dialog_block form-item-inp">
    <el-dialog
      :upBanList="upBanList"
      :title="title"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      @close="isClose"
      :visible.sync="show"
    >
      <el-form
        ref="comForm"
        :model="form"
        style="padding: 0 0.5rem"
        label-width="1.8rem"
      >
        <template v-for="item in edit === 'add' ? formListAdd : formListEdit">
          <el-form-item
            :key="item.prop"
            :label="item.label"
            :prop="item.prop || ''"
            :required="item.required || false"
            :rules="item.rules || null"
            :size="item.size || ''"
          >
            <input
              v-if="item.type === 'password' && edit !== 'view'"
              type="password"
              style="display: block; width: 0px; height: 0px; opacity: 0"
              placeholder=""
              resize="none"
            />
            <el-input
              v-if="item.type === 'text' && edit !== 'view'"
              :disabled="item.disabled"
              v-model="form[item.prop]"
            ></el-input>
            <el-input
              v-else-if="item.type === 'number' && edit !== 'view'"
              :disabled="item.disabled"
              v-model="form[item.prop]"
            ></el-input>

            <el-input
              v-else-if="item.type === 'password' && edit !== 'view'"
              type="password"
              :disabled="item.disabled"
              v-model="form[item.prop]"
            ></el-input>
            <el-select
              v-else-if="item.type === 'select' && edit !== 'view'"
              @change="AddSelectDept"
              :disabled="item.disabled"
              style="width: 100%"
              :multiple="item.multiple || false"
              v-model="form.gradeIds"
              placeholder="请选择班级"
            >
              <el-option
                v-for="val in item.options"
                :key="val.value"
                :label="val.label"
                :value="val.value"
              >
              </el-option>
            </el-select>
            <el-upload
              :headers="{ token: $store.state.userInfo.token }"
              v-else-if="item.type === 'upload' && edit !== 'view'"
              class="avatar-uploader"
              :action="constant.UPLOAD_URL + '/file/upload'"
              :show-file-list="false"
              :on-success="(v) => handleAvatarSuccess(v, item.prop)"
              :before-upload="beforeAvatarUpload"
            >
              <img
                v-if="form[item.prop]"
                :src="form[item.prop]"
                class="avatar"
              />
              <i v-else class="el-icon-plus avatar-uploader-icon"></i>
            </el-upload>

            <el-date-picker
              v-else-if="item.type === 'date' && edit !== 'view'"
              style="width: 100%"
              v-model="form[item.prop]"
              :disabled="item.disabled"
              type="date"
              format="yyyy-MM-dd"
              value-format="yyyy-MM-dd"
              placeholder="选择日期"
              :picker-options="pickerOptions"
            >
            </el-date-picker>

            <el-radio-group
              v-else-if="item.type === 'radio' && edit !== 'view'"
              :disabled="item.disabled"
              v-model="form[item.prop]"
            >
              <el-radio
                :label="val.value"
                v-for="val in item.options"
                :key="val.value"
                >{{ val.label }}</el-radio
              >
            </el-radio-group>
            <span>{{ item.default }}</span>
            <span
              v-if="
                edit === 'view' && item.type !== 'upload' && form[item.prop]
              "
              >{{ form[item.prop] }}</span
            >
            <!-- <img v-else :src="form[item.prop]" /> -->
            <el-image
              v-if="
                edit === 'view' && item.type === 'upload' && form[item.prop]
              "
              style="width: 1rem; height: 1rem"
              :src="form[item.prop]"
              :z-index="20000"
              :preview-src-list="[form[item.prop]]"
            >
            </el-image>
            <input
              v-if="item.type === 'number' && edit !== 'view'"
              type="text"
              style="display: block; width: 0px; height: 0px; opacity: 0"
            />
          </el-form-item>
        </template>
      </el-form>
      <slot name="desc"></slot>
      <div style="text-align: center">
        <el-button v-if="edit !== 'view'" type="primary" @click="submitForm"
          >提交</el-button
        >
        <el-button @click="isClose">取消</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
// import { number } from 'echarts'
export default {
  name: "tablePage",
  components: {},
  props: {
    // 更新班级列表
    upBanList: {
      type: String,
      default: "",
    },
    title: {
      type: String,
      default: "",
    },
    // 表单配置
    list: {
      type: Array,
      default: () => {
        return [];
      },
    },
    // 初始表单数据
    params: {
      type: Object,
      default: () => {
        return {};
      },
    },
    // edit是否是编辑状态
    edit: {
      type: String,
      default: "add",
    },
  },
  watch: {
    params: {
      immediate: true,
      handler: function (val) {
        this.form = val;
      },
    },
    upBanList: {
      immediate: true,
      handler: function () {
        this.getClassInfo();
      },
    },
  },
  data() {
    var checkPhone = (rule, value, callback) => {
      if (!value) {
        callback(new Error("请输入电话号码"));
      } else {
        if (!this.common.isMobile(value)) {
          callback(new Error("请输入正确格式电话号码"));
        } else {
          callback();
        }
      }
    };
    var checkName = (rule, value, callback) => {
      if (!this.common.textNumberCheck(value, 25)) {
        callback(new Error("请输入正确格式姓名"));
      } else {
        callback();
      }
    };
    var checkNatives = (rule, value, callback) => {
      if (value) {
        if (!this.common.validateLength(value, 50)) {
          callback(new Error("请输入正确格式籍贯"));
        } else {
          callback();
        }
      } else {
        callback();
      }
    };
    var checkGraduateSchool = (rule, value, callback) => {
      if (value) {
        if (!this.common.validateIsAllChinese(value)) {
          callback(new Error("请输入正确格式毕业院校"));
        } else {
          callback();
        }
      } else {
        callback();
      }
    };
    var checkEducation = (rule, value, callback) => {
      if (value) {
        if (!this.common.validateIsAllChinese(value)) {
          callback(new Error("请输入正确格式学历"));
        } else {
          callback();
        }
      } else {
        callback();
      }
    };
    var checkIdCard = (rule, value, callback) => {
      if (value) {
        if (!this.common.validateIdCard(value)) {
          callback(new Error("请输入正确格式身份证号"));
        } else {
          callback();
        }
      } else {
        callback();
      }
    };
    var checkTeacherCertificateNumber = (rule, value, callback) => {
      if (value) {
        if (!this.common.validateLength(value, 25)) {
          callback(new Error("请输入正确格式教师资格证编号"));
        } else {
          callback();
        }
      } else {
        callback();
      }
    };
    var checkPassword = (rule, value, callback) => {
      if (value) {
        if (!this.common.validateLength(value, 16)) {
          callback(new Error("请输入正确格式登录密码"));
        } else {
          callback();
        }
      } else {
        callback();
      }
    };
    var checkEmail = (rule, value, callback) => {
      if (value) {
        if (!this.common.validateLength(value, 25)) {
          callback(new Error("请输入正确邮箱"));
        } else {
          if (this.common.isEmail(value)) {
            callback();
          } else {
            callback(new Error("请输入正确邮箱"));
          }
        }
      } else {
        callback();
      }
    };
    return {
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() > Date.now() - 8.64e6;
        },
      },
      form: {},
      show: false,
      gradeLists: [], // 班级列表
      formListEdit: [
        {
          prop: "name",
          type: "text",
          label: "姓名",
          rules: [
            { required: true, message: "请输入姓名", trigger: "blur" },
            { validator: checkName, trigger: "blur" },
          ],
        },
        {
          prop: "sex",
          type: "radio",
          label: "性别",
          rules: [{ required: true, message: "请选择性别", trigger: "blur" }],
          options: [
            {
              label: "男",
              value: "1",
            },
            {
              label: "女",
              value: "2",
            },
          ],
        },
        {
          prop: "natives",
          type: "text",
          label: "籍贯",
          rules: [
            { required: false, message: "请输入籍贯", trigger: "blur" },
            { validator: checkNatives, trigger: "blur" },
          ],
        },
        {
          prop: "graduateSchool",
          type: "text",
          label: "毕业院校",
          rules: [
            { required: false, message: "请输入毕业院校", trigger: "blur" },
            { validator: checkGraduateSchool, trigger: "blur" },
          ],
        },
        {
          prop: "education",
          type: "text",
          label: "学历",
          rules: [
            { required: false, message: "请输入学历", trigger: "blur" },
            { validator: checkEducation, trigger: "blur" },
          ],
        },
        {
          prop: "idCard",
          type: "text",
          label: "身份证号",
          rules: [
            { required: true, message: "请输入身份证号", trigger: "blur" },
            { validator: checkIdCard, trigger: "blur" },
          ],
        },
        {
          prop: "teacherCertificateNumber",
          type: "text",
          label: "教师资格证编号",
          rules: [
            {
              required: false,
              message: "请输入教师资格证编号",
              trigger: "blur",
            },
            { validator: checkTeacherCertificateNumber, trigger: "blur" },
          ],
        },
        {
          prop: "certificateTime",
          type: "date",
          label: "获取证书时间",
          rules: [
            { required: false, message: "请选择证书时间", trigger: "blur" },
          ],
        },
        {
          prop: "birthday",
          type: "date",
          label: "出生日期",
          multiple: true,
          rules: [
            { required: false, message: "请选择出生日期", trigger: "blur" },
          ],
        },
        {
          prop: "gradeIdsValue",
          type: "select",
          label: "所带班级",
          multiple: true,
          rules: [
            { required: false, message: "请选择所带班级", trigger: "blur" },
          ],
          options: [],
        },
        {
          prop: "photo",
          type: "upload",
          label: "照片",
        },
        {
          prop: "phone",
          type: "number",
          label: " 教师手机号",
          rules: [{ required: true, validator: checkPhone, trigger: "blur" }],
        },
        {
          prop: "password",
          type: "password",
          label: " 登录密码",
          rules: [
            { required: false, message: "请输入登录密码", trigger: "blur" },
            { validator: checkPassword, trigger: "blur" },
          ],
        },
        {
          prop: "againPassword",
          type: "password",
          label: " 再次确认密码",
          rules: [
            { required: false, message: "请再次输入密码", trigger: "blur" },
            { validator: this.handleAgainPassword, trigger: "blur" },
          ],
        },
        {
          prop: "email",
          type: "text",
          label: " 邮箱",
          rules: [
            { required: false, message: "请输入邮箱", trigger: "blur" },
            { validator: checkEmail, trigger: "blur" },
          ],
        },
      ],
      formListAdd: [
        {
          prop: "name",
          type: "text",
          label: "姓名",
          rules: [
            { required: true, message: "请输入姓名", trigger: "blur" },
            { validator: checkName, trigger: "blur" },
          ],
        },
        {
          prop: "sex",
          type: "radio",
          label: "性别",
          rules: [{ required: true, message: "请选择性别", trigger: "blur" }],
          options: [
            {
              label: "男",
              value: "1",
            },
            {
              label: "女",
              value: "2",
            },
          ],
        },
        {
          prop: "natives",
          type: "text",
          label: "籍贯",
          rules: [{ validator: checkNatives, trigger: "blur" }],
        },
        {
          prop: "graduateSchool",
          type: "text",
          label: "毕业院校",
          rules: [{ validator: checkGraduateSchool, trigger: "blur" }],
        },
        {
          prop: "education",
          type: "text",
          label: "学历",
          rules: [{ validator: checkEducation, trigger: "blur" }],
        },
        {
          prop: "idCard",
          type: "text",
          label: "身份证号",
          rules: [
            { required: true, message: "请输入身份证号", trigger: "blur" },
            { validator: checkIdCard, trigger: "blur" },
          ],
        },
        {
          prop: "teacherCertificateNumber",
          type: "text",
          label: "教师资格证编号",
          rules: [
            { validator: checkTeacherCertificateNumber, trigger: "blur" },
          ],
        },
        {
          prop: "certificateTime",
          type: "date",
          label: "获取证书时间",
        },
        {
          prop: "birthday",
          type: "date",
          label: "出生日期",
        },
        {
          prop: "gradeIdsValue",
          type: "select",
          label: "所带班级",
          multiple: true,
          rules: [
            { required: false, message: "请选择所带班级", trigger: "blur" },
          ],
          options: [],
        },
        {
          prop: "photo",
          type: "upload",
          label: "照片",
        },
        {
          prop: "phone",
          type: "number",
          label: " 教师手机号",
          rules: [{ required: true, validator: checkPhone, trigger: "blur" }],
        },
        {
          prop: "password",
          type: "password",
          label: " 登录密码",
          rules: [
            { required: true, message: "请输入登录密码", trigger: "blur" },
            { validator: checkPassword, trigger: "blur" },
          ],
        },
        {
          prop: "againPassword",
          type: "password",
          label: " 再次确认密码",
          rules: [
            { required: true, message: "请再次输入密码", trigger: "blur" },
            { validator: this.handleAgainPassword, trigger: "blur" },
          ],
        },
        {
          prop: "email",
          type: "text",
          label: " 邮箱",
          rules: [
            { type: "email", message: "请输入正确的邮箱地址", trigger: "blur" },
            { validator: checkEmail, trigger: "blur" },
          ],
        },
      ],
    };
  },
  created() {},
  mounted() {
    this.getClassInfo();
  },
  methods: {
    AddSelectDept(val) {
      // 筛选出所选班级的id
      var idArr = [];
      for (var i in val) {
        if (typeof val[i] == "string") {
          var gradeLists = this.gradeLists;
          for (var j in gradeLists) {
            if (gradeLists[j].atteGrade.name == val[i]) {
              idArr.push(gradeLists[j].atteGrade.id);
            }
          }
          console.log(gradeLists);
        } else {
          idArr.push(val[i]);
        }
      }
      this.form.gradeIds = idArr;
      this.form.gradeIdsValue = idArr;
      console.log(this.form.gradeIds);
    },
    handleAvatarSuccess(res, prop) {
      console.log(res);
      // console.log(res,prop);
      // this.form[prop] = '/'+res.data.filename;
      this.form[prop] = res.data.url;
    },
    // 再次输入密码验证
    handleAgainPassword(rule, value, callback) {
      if (!value) {
        callback();
      } else {
        if (!this.common.validateLength(value, 16)) {
          callback(new Error("请输入正确格式登录密码"));
        } else {
          if (value && this.form.password && value != this.form.password) {
            callback(new Error("两次输入密码不一致"));
          } else {
            callback();
          }
        }
      }
    },
    beforeAvatarUpload(file) {
      // console.log(file);
      const isJPG = file.type === "image/jpeg" || file.type === "image/png";
      const isLt2M = file.size / 1024 / 1024 < 2;

      if (!isJPG) {
        this.$notice({
          message: "上传头像图片只能是 JPG/PNG 格式!",
        }).isShow();
      }
      if (!isLt2M) {
        this.$notice({
          message: "上传头像图片大小不能超过 2MB!",
        }).isShow();
      }
      return isJPG && isLt2M;
    },

    submitForm() {
      const _val = "comForm";
      const self = this;
      console.log(self.form, "self.form");
      // eslint-disable-next-line consistent-return
      return self.$refs[_val].validate((valid) => {
        console.log("完成", self.form);
        if (valid) {
          self.form.name = self.form.name.trim();
          self.form.idCard = self.form.idCard.trim();
          self.form.phone = self.form.phone.trim();
          this.$emit("onSubmit", self.form);
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    // 获取班级信息
    getClassInfo() {
      let data = {
        schoolId: this.$store.state.userInfo.schoolId,
      };
      this.api.school.findSelectBySchoolId(data).then((res) => {
        if (res.flag == true) {
          this.gradeLists = res.data;
          let arr = [];
          res.data.forEach((item) => {
            arr.push({
              value: item.id,
              label: item.name,
            });
          });
          this.formListAdd.forEach((item) => {
            if (item.prop === "gradeIdsValue") {
              item.options = arr;
              this.$set(item, "options", arr);
            }
          });
          this.formListEdit.forEach((item) => {
            if (item.prop === "gradeIdsValue") {
              item.options = arr;
              this.$set(item, "options", arr);
            }
          });
        }
      });
    },
    isOpen() {
      this.show = true;
    },
    isClose() {
      this.show = false;
      this.$emit("close", "");
    },
  },
};
</script>

<style lang="scss" scoped></style>
<style lang="scss">
.dialog_block {
  .el-dialog {
    margin-top: 10vh !important;
  }

  .el-form-item {
    margin-bottom: 22px;
  }

  .avatar-uploader .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
  }

  .avatar-uploader .el-upload:hover {
    border-color: #409eff;
  }

  .avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 178px;
    height: 178px;
    line-height: 178px;
    text-align: center;
  }

  .avatar {
    width: 118px;
    height: 118px;
    display: block;
  }
}
</style>